@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Michroma:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@font-face {
    font-family: 'Eurostile';
    src: url('./fonts/Eurostile.ttf');
}

@font-face {
  font-family: 'EurostileBold';
  src: url('./fonts/EurostileBold.ttf');
}

@font-face {
  font-family: 'EuropaRegular';
  src: url('./fonts/EuropaRegular.ttf');
}
html,
body {
  /* padding: 4px; */
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'EurostileBold','Eurostile','EuropaRegular' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

}
/* humberger animation */
#icon {
    width: 70px;
    height: 50px;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .paths {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: #171a21;
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  .cross path#top,
  .cross path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  .cross path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
/* CSS for the loader */
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #6601ff;
  border-top: 4px solid rgba(0, 0, 0, 0.065);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-28 {
  position: relative;
  -webkit-animation: 2s loader-28-1 infinite;
          animation: 2s loader-28-1 infinite;
}
.loader-28:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  border-radius: 80% 20%;
  border: 0.1em solid currentcolor;
  transform: rotate(45deg);
  border-width: 0.1em 0.05em 0.05em 0.1em;
}
.loader-28:after {
  content: "";
  display: block;
  width: 0.2em;
  height: 0.2em;
  position: absolute;
  top: 0.4em;
  left: 50%;
  border-radius: 50%;
  box-shadow: -0.07em 0.07em 0 0.1em currentcolor;
  -webkit-animation: 2s loader-28-2 linear infinite;
          animation: 2s loader-28-2 linear infinite;
}

@-webkit-keyframes loader-28-1 {
  0%, 100% {
    transform: scaleY(1);
  }
  10% {
    transform: scaleY(0);
  }
  20% {
    transform: scaleY(1);
  }
}

@keyframes loader-28-1 {
  0%, 100% {
    transform: scaleY(1);
  }
  10% {
    transform: scaleY(0);
  }
  20% {
    transform: scaleY(1);
  }
}
@-webkit-keyframes loader-28-2 {
  0%, 100% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-100%);
  }
  50% {
    transform: transalteX(200%);
  }
}
@keyframes loader-28-2 {
  0%, 100% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-100%);
  }
  50% {
    transform: transalteX(200%);
  }
}

/* ///////////////////////////////////////// */

